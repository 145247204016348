import { Link } from "gatsby";
import React, { FC } from "react";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";

export const Pagination: FC<{ pageContext: any }> = ({ pageContext }) => {
  console.log({ pageContext });
  const { previousPagePath, nextPagePath, numberOfPages, humanPageNumber } =
    pageContext;

  const activePageNumber = `border-transparent text-blue-900 hover:text-blue-800 hover:border-blue-800 border-t-2 pt-4 px-4 inline-flex items-center text-lg font-medium`;
  const inactivePageNumber = `border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-lg font-medium`;

  return (
    <nav className="border-t border-none px-4 flex items-center justify-between sm:px-0 py-6 max-w-7xl mx-auto">
      <div className="-mt-px w-0 flex-1 flex">
        {previousPagePath === "" ? null : (
          <Link
            to={previousPagePath}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            <ArrowNarrowLeftIcon
              className="mr-3 h-8 w-8 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </Link>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {Array(numberOfPages)
          .fill(0)
          .map((page, i) => (
            <Link
              key={i}
              to={i === 0 ? `/blog/` : `/blog/${i + 1}`}
              className={
                humanPageNumber === i + 1
                  ? activePageNumber
                  : inactivePageNumber
              }
            >
              {i + 1}
            </Link>
          ))}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {nextPagePath === "" ? null : (
          <Link
            to={nextPagePath}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            Next
            <ArrowNarrowRightIcon
              className="ml-3 h-8 w-8 text-gray-400"
              aria-hidden="true"
            />
          </Link>
        )}
      </div>
    </nav>
  );
};
