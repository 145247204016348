import React, { FC } from "react";
import { Layout } from "../shared/components/Layout";
import { graphql, Link } from "gatsby";
import { Pagination } from "../shared/components/Layout/Pagination";

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulBlog(
      sort: { fields: updatedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          updatedAt(formatString: "LL")
          author
          contentful_id
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;

const BlogPreview: FC<{
  location: any;
  pageContext: any;
  data: any;
}> = ({ location, pageContext, data }) => {
  const { edges } = data.allContentfulBlog;

  return (
    <Layout
      seo={{
        url: "https://syedalihusain.netlify.app/blog/",
        description: "Dr. Syed Ali Husain's blog, with a focus on Nephrology",
        title: "Blog | Dr. Syed Ali Husain",
        keywords: [
          "Blog",
          "Doctor",
          "MD",
          "Kidney",
          "Medicine",
          "Kidney Research",
          "Nephrologist",
          "Nephrology",
        ],
      }}
      location={location}
    >
      {" "}
      <div className="bg-gray-50 pt-16 pb-20 lg:pt-24 lg:pb-28 min-full-screen">
        <div className="relative divide-y-2 divide-gray-200 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-blue-900 sm:text-4xl">
              Blog
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
              <p className="text-xl text-gray-500">Dr. Syed Ali Husain</p>
            </div>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {edges.map(({ node }) => (
              <div key={node.title}>
                <p className="text-sm text-gray-500">
                  <time>{node.updatedAt}</time>
                </p>
                <Link to={`/blog/${node.slug}`} className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    {node.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {node.excerpt.excerpt}
                  </p>
                </Link>
                <div className="mt-3">
                  <Link
                    to={`/blog/${node.slug}`}
                    className="text-base font-semibold text-blue-900 hover:text-blue-800"
                  >
                    Read full story
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Pagination pageContext={pageContext} />
    </Layout>
  );
};

export default BlogPreview;
